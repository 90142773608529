/** @format */

import { useMutation } from "@apollo/client";
import { ENUM_FLEET_STATUS } from "@prisma/client";
import {
  MAX_LIMIT_ENTERPRISE,
  MAX_LIMIT_NON_ENTERPRISE,
} from "@roadflex/constants";
import { DELETE_VEHICLE } from "@roadflex/graphql";
import { useDeleteVehicles, useReadVehicles } from "@roadflex/react-hooks";
import { ChildFeatureType, FleetExtended, UserAuthType } from "@roadflex/types";
import { useState } from "react";
import { Step } from "react-joyride";
import { Loader } from "../../loader";
import { Toast } from "../../toast-message/toast";
import { VehiclesUI } from "./vehicles.ui";

interface VehiclesProps {
  readCurrentUserAuth: UserAuthType;
  loading: boolean;
  childFeatures: ChildFeatureType[];
}

export const Vehicles = ({
  readCurrentUserAuth,
  loading,
  childFeatures,
}: VehiclesProps) => {
  const [tabOpen, setTabOpen] = useState(1);
  const maxVehicles = readCurrentUserAuth?.fuelCard?.customerType?.includes(
    "ENTERPRISE",
  )
    ? MAX_LIMIT_ENTERPRISE
    : MAX_LIMIT_NON_ENTERPRISE;

  const tabs = [
    { id: 1, name: "Vehicles", href: "", current: true },
    { id: 2, name: "Archived Vehicles", href: "", current: false },
  ];

  const { getVehiclesData, getVehiclesRefetch, getVehiclesLoading } =
    useReadVehicles([]);

  const {
    resetForm,
    deleteVehiclesHandleSubmit,
    deleteVehiclesSetValues,
    ...rest
  } = useDeleteVehicles({
    initialValues: {
      idList: [],
    },
    onSubmit(res, err) {
      resetForm();
      if (err) {
        Toast({ type: "error", message: err.message });
        return;
      }
      if (res?.code === "200") {
        Toast({ type: "success", message: res.message });
      }
    },
  });

  const [deleteVehicles] = useMutation<{
    deleteFleet: {
      message: string;
      code: string;
    };
  }>(DELETE_VEHICLE);

  const steps: Step[] = [
    {
      title: "Welcome",
      content:
        "Welcome to the Vehicles page. Once a vehicle is added, you can link a vehicle to a card.",
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
      },
      placement: "center",
      target: "body",
    },
    {
      content:
        "To start, you can add a vehicle by clicking here. We require basic information such as the license plate of the vehicle.",
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
      },
      spotlightPadding: 10,
      target: ".step-2",
    },
    {
      content:
        "To edit or delete a vehicle, click here. Non-enterprise accounts can add up to 40 vehicles. Enterprise accounts can add up to 400 vehicles.",
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
      },
      spotlightPadding: 10,
      target: ".step-3",
    },
    {
      target: ".step-4",
      title: "Help Center",
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
      },
      spotlightPadding: 10,
      content:
        'If you need help or would like to go through the navigation again, click "Help Center".',
    },
  ];

  const [joyride, setJoyride] = useState(false);

  if (loading) {
    return <Loader />;
  }

  const vehiclesList = getVehiclesData?.readVehicles?.vehicles || [];
  const activeVehicleList: FleetExtended[] = [];
  const archivedVehicleList: FleetExtended[] = [];

  vehiclesList?.forEach((vehicle: FleetExtended) => {
    const newVehicle = { ...vehicle };
    newVehicle.createdAt = new Date(vehicle.createdAt);
    if (newVehicle?.status === ENUM_FLEET_STATUS.DELETED) {
      archivedVehicleList.push(newVehicle);
    } else {
      activeVehicleList.push(newVehicle);
    }
  });

  return (
    <VehiclesUI
      {...{
        vehiclesList: activeVehicleList,
        archivedVehicleList,
        getVehiclesLoading,
        getVehiclesRefetch,
        deleteVehiclesHandleSubmit,
        deleteVehiclesSetValues,
        steps,
        joyride,
        setJoyride,
        tabs,
        tabOpen,
        setTabOpen,
        maxVehicles,
        childFeatures,
      }}
    />
  );
};
