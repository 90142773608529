/** @format */

import { useMutation } from "@apollo/client";
import { RadioGroup } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { ENUM_CUSTOMER_TYPE } from "@prisma/client";
import { MINIMUM_MANUAL_AMOUNT } from "@roadflex/constants";
import {
  STRIPE_CREATE_MANUAL_ACH,
  STRIPE_CREATE_MANUAL_PAYMENT,
} from "@roadflex/graphql";
import { PlaidType, UserAuthType } from "@roadflex/types";
import { humanize } from "@roadflex/utils";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { Button, ButtonVariant } from "../../buttons";
import { PlaidPaymentMethods } from "../../fuelcard-components/plaid/PlaidPaymentMethods";
import { StripePaymentMethods } from "../../fuelcard-components/stripe-payment-methods/stripe-payment-methods";
import { Toast } from "../../toast-message/toast";

type MakePaymentModalProps = {
  open: boolean;
  setOpen: (val: boolean) => void;
  plaidData: PlaidType[];
  businessId: string;
  customerType: ENUM_CUSTOMER_TYPE;
  allowACHPaymentMethod: boolean;
  allowDebitCardPaymentMethod: boolean;
  readCurrentUserAuth: UserAuthType;
  phrasing: string;
};

export const MakePaymentModal = ({
  open,
  setOpen,
  plaidData,
  businessId,
  customerType,
  allowACHPaymentMethod,
  allowDebitCardPaymentMethod,
  readCurrentUserAuth,
  phrasing,
}: MakePaymentModalProps) => {
  const [createManualPaymentFn, { loading: processingPayment }] = useMutation<{
    stripeCreateManualPayment: {
      code: string;
      success: boolean;
      type: string;
      message: string;
      status: string;
    };
  }>(STRIPE_CREATE_MANUAL_PAYMENT, {
    fetchPolicy: "no-cache",
  });
  const paymentTypes = [
    {
      id: 1,
      title: `${phrasing}`,
      turnaround: "Instant",
      disabled: !allowDebitCardPaymentMethod,
    },
    {
      id: 2,
      title: "ACH",
      turnaround: "3-5 business days",
      disabled: !allowACHPaymentMethod,
    },
  ];

  const [createManualACHFn, { loading: processingACH }] = useMutation<{
    stripeCreateManualACH: {
      code: string;
      success: boolean;
      type: string;
      message: string;
      status: string;
    };
  }>(STRIPE_CREATE_MANUAL_ACH, {
    fetchPolicy: "no-cache",
  });
  const [selectedPaymentType, setSelectedPaymentType] = useState(
    allowDebitCardPaymentMethod ? paymentTypes[0] : paymentTypes[1],
  );
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");
  const [payAmount, setpayAmount] = useState("");
  const filteredPlaidData = plaidData.filter((account) => !account.plaidError);
  const [selectedPlaidAccount, setSelectedPlaidAccount] = useState(
    filteredPlaidData.find((account) => account?.isPrimary)?.accountInfo
      ?.account_id ||
      filteredPlaidData[0]?.accountInfo?.account_id ||
      null,
  );
  const validateValue = (value: string | undefined): void => {
    const rawValue = value === undefined ? "undefined" : value;
    setpayAmount(rawValue || " ");
    // console.log(rawValue);
    if (Number.isNaN(Number(value)) && value !== undefined) {
      Toast({ type: "error", message: "Enter a valid number" });
    }
  };

  const onPaymentTypeChange = (value: typeof paymentTypes[0]) => {
    setSelectedPaymentType(value);
  };

  const onMakePayment = async () => {
    if (Number(payAmount) * 100 < MINIMUM_MANUAL_AMOUNT) {
      Toast({ type: "error", message: "Minimum $25 is required" });
      return;
    }
    if (selectedPaymentType.id === 1) {
      try {
        const res = await createManualPaymentFn({
          variables: {
            data: {
              paymentMethodId: selectedPaymentMethod,
              amount: Math.floor(Number(payAmount) * 100),
            },
          },
        });
        if (res?.data?.stripeCreateManualPayment.success) {
          Toast({ type: "success", message: "Payment successful" });
          setOpen(false);
        } else {
          Toast({
            type: "error",
            message:
              res?.data?.stripeCreateManualPayment.message ||
              "Something went wrong",
          });
        }
      } catch (err: unknown) {
        if (err instanceof Error) {
          Toast({ type: "error", message: err.message });
        } else {
          Toast({ type: "error", message: "Something went wrong" });
        }
      }
    } else {
      try {
        const plaidAccount = filteredPlaidData.find((account) =>
          account.authInfo?.accounts.some(
            (subAccount) => subAccount.account_id === selectedPlaidAccount,
          ),
        );
        const res = await createManualACHFn({
          variables: {
            data: {
              accountId: selectedPlaidAccount,
              plaidId: plaidAccount?.id || "",
              amount: Number(payAmount) * 100,
            },
          },
        });
        if (res?.data?.stripeCreateManualACH.success) {
          Toast({
            type: "success",
            message: `Payment ${humanize(
              res.data.stripeCreateManualACH.status,
            )}. ${
              res.data.stripeCreateManualACH.status.toUpperCase() === "PENDING"
                ? "Please check the status in 3-5 days in payment history."
                : ""
            }`,
          });
          setOpen(false);
        } else {
          Toast({
            type: "error",
            message:
              res?.data?.stripeCreateManualACH.message ||
              "Something went wrong",
          });
        }
      } catch (err: unknown) {
        if (err instanceof Error) {
          Toast({ type: "error", message: err.message });
        } else {
          Toast({ type: "error", message: "Something went wrong" });
        }
      }
    }
  };
  return (
    <Dialog
      header={`Make Payment`}
      visible={open}
      style={{ minWidth: "40vw", maxWidth: "80vw", fontFamily: "Inter" }}
      onHide={() => setOpen(false)}
    >
      <div className="min-h-[300px] mt-0.5">
        <RadioGroup
          className="mb-4 h-[164px] sm:h-[78px]"
          value={selectedPaymentType}
          onChange={onPaymentTypeChange}
        >
          <div className="grid grid-cols-1 gap-y-2 sm:grid-cols-2 sm:gap-x-3">
            {paymentTypes.map((paymentType) => (
              <RadioGroup.Option
                disabled={paymentType.disabled}
                key={paymentType.id}
                value={paymentType}
                className={({ disabled }) =>
                  classNames(
                    disabled ? "opacity-70" : "",
                    "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none",
                  )
                }
              >
                <>
                  <span className="flex flex-1">
                    <span className="flex flex-col">
                      <RadioGroup.Label
                        as="span"
                        className="block text-sm font-medium text-gray-900"
                      >
                        {paymentType.title}
                      </RadioGroup.Label>
                      <RadioGroup.Description
                        as="span"
                        className="flex items-center mt-1 text-sm text-gray-500"
                      >
                        {paymentType.turnaround}
                      </RadioGroup.Description>
                    </span>
                  </span>
                  {paymentType.id === selectedPaymentType.id ? (
                    <CheckCircleIcon
                      className="w-5 h-5 text-gray-600"
                      aria-hidden="true"
                    />
                  ) : null}
                  <span
                    className={classNames(
                      paymentType.id === selectedPaymentType.id
                        ? "border-gray-500 border"
                        : "border-transparent",
                      "pointer-events-none absolute -inset-px rounded-lg",
                    )}
                    aria-hidden="true"
                  />
                </>
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
        {selectedPaymentType.id === 1 && (
          <StripePaymentMethods
            ui="list"
            selectedPaymentMethod={selectedPaymentMethod}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
            phrasing={phrasing}
          ></StripePaymentMethods>
        )}
        {selectedPaymentType.id === 2 && businessId && (
          <PlaidPaymentMethods
            ui="dropdown"
            plaidData={filteredPlaidData}
            businessId={businessId}
            selectedPlaidAccount={selectedPlaidAccount}
            setSelectedPlaidAccount={setSelectedPlaidAccount}
            readCurrentUserAuth={readCurrentUserAuth}
          ></PlaidPaymentMethods>
        )}
        <label className="mb-1 text-sm">
          Amount (min: $25.00)<span className="text-red-500">*</span>
        </label>
        <CurrencyInput
          placeholder="$"
          className="block w-full py-2 pl-3 pr-12 border border-black rounded-md sm:text-sm focus:outline-none focus:ring-0 focus:border-black"
          allowDecimals={true}
          onValueChange={validateValue}
          prefix={"$"}
          step={10}
        />
        <div className="flex flex-row justify-end mt-4">
          <Button
            variant={ButtonVariant.Black}
            loading={processingPayment || processingACH}
            onClick={onMakePayment}
            disabled={
              processingPayment ||
              processingACH ||
              (selectedPaymentType.id === 1 && !allowDebitCardPaymentMethod) ||
              (selectedPaymentType.id === 2 && !allowACHPaymentMethod)
            }
          >
            Make Payment
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
