/** @format */

import { useQuery } from "@apollo/client";
import { ArrowDownOnSquareIcon } from "@heroicons/react/24/outline";
import { ENUM_BILLING_CYCLE_STATUS } from "@prisma/client";
import { READ_BILLING_CYCLE_PAGINATION } from "@roadflex/graphql";
import { useReadAppDashboardData } from "@roadflex/react-hooks";
import { BillingCycleType, UserAuthType } from "@roadflex/types";
import { convertISOStringToDateNew, humanizeEnum } from "@roadflex/utils";
import { dollarFormatter } from "@roadflex/web-lib";
import classNames from "classnames";
import { DataTablePFSEvent } from "primereact/datatable";
import { useEffect, useState } from "react";
import { Step } from "react-joyride";
import { Button, ButtonVariant } from "../../buttons";
import { Loader } from "../../loader";
import { Toast } from "../../toast-message/toast";
import StatementsUI from "./statement.ui";

const billingPeriodTemplate = (rowData: BillingCycleType, tz: string) => {
  return (
    <span className="flex text-sm text-gray-500 whitespace-nowrap">
      <div>
        <div>
          {
            convertISOStringToDateNew(
              rowData.startingDate.toString(),
              false,
              false,
              true,
              tz,
            ).split(",")[0]
          }
        </div>
        <div className="text-sm">
          {
            convertISOStringToDateNew(
              rowData.startingDate.toString(),
              false,
              false,
              true,
              tz,
            ).split(",")[1]
          }
        </div>
      </div>
      <div className="px-1">-</div>
      <div>
        {" "}
        <div>
          {
            convertISOStringToDateNew(
              rowData.endingDate.toString(),
              false,
              false,
              true,
              tz,
            ).split(",")[0]
          }
        </div>
        <div className="text-sm">
          {
            convertISOStringToDateNew(
              rowData.endingDate.toString(),
              false,
              false,
              true,
              tz,
            ).split(",")[1]
          }
        </div>
      </div>
      {/* {convertISOStringToPSTDateTime(rowData?.startingDate.toString(), true)}-
      {convertISOStringToPSTDateTime(rowData?.endingDate.toString(), true)} */}
    </span>
  );
};

const amountTemplate = (val: BillingCycleType, sign: string) => {
  return (
    <span className="text-sm text-gray-500 whitespace-nowrap">
      {sign}
      {dollarFormatter(+val / 100 || 0)}
    </span>
  );
};

const amountOweTemplate = (rowData: BillingCycleType) => {
  return (
    <span className="text-sm text-gray-500 whitespace-nowrap">
      {dollarFormatter(
        (+rowData.spendings - +rowData.rewards + +rowData.lateFees) / 100 || 0,
      )}
    </span>
  );
};

const billingCycleInitialLazyParams: DataTablePFSEvent = {
  first: 0,
  rows: 10,
  page: 0,
  filters: {},
  sortField: "startingDate",
  sortOrder: -1,
  multiSortMeta: null,
};
type StatementsProps = {
  readCurrentUserAuth: UserAuthType;
  loading: boolean;
};

export const Statements = ({
  readCurrentUserAuth,
  loading,
}: StatementsProps) => {
  const [modal, setmodal] = useState(false);
  const [selected, setselected] = useState<BillingCycleType | null>();
  const [billingCycleLazyParams, setBillingCycleLazyParams] =
    useState<DataTablePFSEvent>({
      ...billingCycleInitialLazyParams,
    });
  const {
    data,
    loading: readAppDashboardDataLoading,
    refetch: refetchBillingCycleList,
  } = useReadAppDashboardData();

  const {
    data: billingCycleList,
    loading: billingCycleListLoading,
    ...rest
  } = useQuery<{
    readBillingCyclePagination: {
      code: string;
      message: string;
      billingCycleList: BillingCycleType[];
      totalCount: number;
    };
  }>(READ_BILLING_CYCLE_PAGINATION, {
    fetchPolicy: "no-cache",
    variables: {
      data: {
        ...billingCycleLazyParams,
      },
    },
  });

  useEffect(() => {
    refetchBillingCycleList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingCycleLazyParams]);
  const [joyride, setJoyride] = useState(false);

  useEffect(() => {
    if (
      !billingCycleListLoading &&
      billingCycleList &&
      billingCycleList?.readBillingCyclePagination?.code !== "200"
    ) {
      Toast({
        type: "error",
        message: billingCycleList.readBillingCyclePagination.message || "",
      });
    }
  }, [billingCycleList, billingCycleListLoading]);

  if (loading) {
    return <Loader />;
  }

  const steps: Step[] = [
    {
      title: "Welcome",
      content: "Welcome to the Statements page.",
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
      },
      placement: "center",
      target: "body",
    },
    {
      title: "Statement Generation",
      content:
        "If your account is on a weekly billing cycle, statements are generated every Thursday 12pm EST. If you are on a monthly billing cycle, statements are generated on the 3rd of the next month.",
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
      },
      spotlightPadding: 10,
      target: ".step-2",
    },
    {
      title: "Amount Due",
      content:
        "In most cases, the amount we will automatically debit the following Monday (weekly billing cycle) or on the 7th of the next month (monthly billing cycle) will be the amount due. If there are past statements that have failed, the amount debited will be more.",
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
      },
      spotlightPadding: 10,
      target: ".step-3",
    },

    {
      title: "Statements Due Date",
      content:
        "If automated payment fails and we do not receive the payment by the due date, a late fee will be imposed the following day.",
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
      },
      spotlightPadding: 10,
      target: ".step-4",
    },
    {
      target: ".step-5",
      title: "Statement Download",
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
      },
      content: "You may download statements to view transaction-level data.",
    },
    {
      target: ".step-6",
      title: "Help Center",
      locale: {
        skip: (
          <span aria-label="skip" style={{ fontSize: "16px" }}>
            End
          </span>
        ),
      },
      spotlightPadding: 10,
      content:
        'If you need help or would like to go through the navigation again, click "Help Center".',
    },
  ];

  const toggleModal = () => setmodal((m) => !m);

  function onSelect(billing: BillingCycleType) {
    setselected(billing);
    toggleModal();
  }

  const statusTemplate = (rowData: BillingCycleType) => {
    const statusString =
      rowData?.createdManually &&
      rowData?.status === ENUM_BILLING_CYCLE_STATUS.GENERATED
        ? "IN_PROGRESS"
        : rowData?.status;

    return (
      <div className="flex items-center justify-between">
        <span
          className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium capitalize ${
            statusString === ENUM_BILLING_CYCLE_STATUS.SUCCEEDED
              ? "bg-green-100 text-green-800"
              : statusString === ENUM_BILLING_CYCLE_STATUS.FAILED
              ? "bg-orange-100 text-orange-800"
              : "bg-gray-300 text-gray-800"
          }`}
        >
          {
            // rowData?.status === ENUM_BILLING_CYCLE_STATUS.PENDING && !rowData.isPaid ? "generated" :
            statusString ? humanizeEnum(statusString) : "-"
          }
        </span>
        <Button
          variant={ButtonVariant.Transparent}
          className="!p-0 ml-3"
          onClick={() => onSelect(rowData)}
          disabled={
            +rowData.spendings - +rowData.rewards + +rowData.lateFees === 0
          }
        >
          <ArrowDownOnSquareIcon
            className={classNames(
              +rowData.spendings - +rowData.rewards + +rowData.lateFees === 0
                ? "text-scarpa-flow-100"
                : "text-scarpa-flow-500",
              "w-5 h-5",
            )}
          ></ArrowDownOnSquareIcon>
        </Button>
      </div>
    );
  };

  const onPageAndSortBillingCycle = (event: DataTablePFSEvent) => {
    setBillingCycleLazyParams({ ...billingCycleLazyParams, ...event });
  };

  return (
    <StatementsUI
      {...{
        readAppDashboardData: data?.readAppDashboardData?.appDashboardData,
        readAppDashboardDataLoading,
        readCurrentUserAuth,
        billingList:
          billingCycleList?.readBillingCyclePagination?.billingCycleList || [],
        totalCount:
          billingCycleList?.readBillingCyclePagination?.totalCount || 0,
        billingCycleLazyParams,
        billingCycleListLoading,
        billingPeriodTemplate,
        onPageAndSortBillingCycle,
        amountTemplate,
        statusTemplate,
        modal,
        selected,
        toggleModal,
        amountOweTemplate,
        joyride,
        setJoyride,
        steps,
      }}
    />
  );
};
