/** @format */

import { useMutation } from "@apollo/client";
import { CONNECT_FLEETIO, FLEETIO_CONNECTED } from "@roadflex/graphql";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import * as Yup from "yup";
import { TextInput } from "../../inputs";
import { Toast } from "../../toast-message/toast";

export interface AddFleetioModalProps {
  openAddFleetioModal: boolean;
  setOpenAddFleetioModal: (value: boolean) => void;
}

export default function AddFleetioModal({
  openAddFleetioModal,
  setOpenAddFleetioModal,
}: AddFleetioModalProps) {
  const [connectFleetioFn] = useMutation<{ persistFleetioToken: boolean }>(
    CONNECT_FLEETIO,
    { refetchQueries: [FLEETIO_CONNECTED] },
  );

  const {
    handleChange,
    handleSubmit,
    values,
    isSubmitting,
    setFieldValue,
    resetForm,
    ...rest
  } = useFormik({
    initialValues: {
      accountToken: "",
      apiKey: "",
    },
    validationSchema: Yup.object().shape({
      accountToken: Yup.string().required("Account token is required"),
      apiKey: Yup.string().required("Api key is required"),
    }),
    onSubmit: async (value) => {
      const data = {
        ...value,
      };
      try {
        // console.log("data => ", data);
        await connectFleetioFn({
          variables: { data },
        });
        Toast({ type: "success", message: "Fleetio connected successfully" });
        setOpenAddFleetioModal(false);
      } catch (err) {
        if (err instanceof Error) {
          Toast({ type: "error", message: err.message });
        } else {
          Toast({ type: "error", message: "Something went wrong" });
        }
      }
    },
  });
  const authenticateButton = (
    <Button
      type="button"
      label="Authenticate"
      icon="pi pi-unlock"
      loading={isSubmitting}
      className="p-button-outlined p-button-danger p-button-sm"
      onClick={(e) => handleSubmit()}
      style={{
        fontFamily: "Inter",
        padding: "0.5rem",
      }}
    />
  );

  const renderFooter = () => {
    return (
      <div>
        <button
          type="button"
          className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          onClick={() => setOpenAddFleetioModal(false)}
        >
          Close
        </button>
      </div>
    );
  };

  return (
    <Dialog
      header={`Connect Fleetio`}
      visible={openAddFleetioModal}
      className="w-[95%] sm:w-[50vw]"
      footer={renderFooter()}
      onHide={() => setOpenAddFleetioModal(false)}
    >
      <div className="mx-auto max-w-7xl ">
        <div className="flex flex-col items-start gap-4 mt-4 flex-nowrap xl:flex-row">
          <div className="flex flex-col w-full text-gray-700">
            <div className="max-w-7xl xl:flex xl:items-stretch">
              <div className="w-full xl:w-2/3">
                <div>Enter the token and api key from your Fleetio account</div>
                <div className="flex mt-3">
                  <TextInput
                    type="text"
                    placeholder="Enter account token"
                    value={values.accountToken}
                    onChange={handleChange}
                    name="accountToken"
                  ></TextInput>
                </div>
                <div className="flex mt-3">
                  <TextInput
                    type="text"
                    placeholder="Enter api key"
                    value={values.apiKey}
                    onChange={handleChange}
                    name="apiKey"
                  ></TextInput>
                </div>
                <div className="flex flex-row-reverse mt-3">
                  {authenticateButton}
                </div>
              </div>

              <div className="w-[1px] h-[inherit] border-r mx-5 border-brown-500"></div>
              <hr className="w-full col-span-5 my-6 border-1 border-brown-500 xl:hidden" />

              <div className="w-full xl:w-1/3 sm:min-w-[350px] text-gray-700">
                <div className="flex flex-col col-span-2 mt-6 xl:mt-0 xl:col-span-1">
                  <div className="mb-2 text-xl font-bold text-black">
                    How it works
                  </div>
                  <ul className="text-sm font-medium list-decimal list-inside">
                    <li className="py-2">
                      Create an API token via the Fleetio UI
                    </li>
                    <li className="py-2">
                      Connect to the fleetio account using the API token
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
