/** @format */

import {
  ENUM_ACCOUNT_TAG_TYPE,
  ENUM_CHILD_USER_TYPE,
  ENUM_NOTIFICATION_CHANNEL,
} from "@prisma/client";
import { appChildUserValidationSchema } from "@roadflex/constants";
import {
  useReadAccountTag,
  useReadAccountUsers,
  useUpdateAccountUser,
} from "@roadflex/react-hooks";
import {
  NotificationsType,
  NotificationsTypeExtended,
  ParameterFilterType,
  UserAuthType,
} from "@roadflex/types";
import { phoneFormatter } from "@roadflex/utils";
import { useEffect, useState } from "react";
import { Toast } from "../../../../src/toast-message/toast";
import { UserUI } from "./user.ui";

type UserProps = {
  readCurrentUserAuth: UserAuthType;
  userId: string;
};
export const User = ({ readCurrentUserAuth, userId }: UserProps) => {
  const tabs = [
    { id: 1, name: "User", current: true },
    { id: 2, name: "Notifications Settings", current: false },
  ];
  const [update, setUpdate] = useState(false);
  const [notifications, setNotifications] = useState<
    NotificationsTypeExtended[]
  >([]);

  const [parameterFilter, setParameterFilter] = useState<ParameterFilterType[]>(
    [],
  );

  const {
    data: accountUsers,
    refetch: accountUsersRefetch,
    loading: accountUsersLoading,
  } = useReadAccountUsers(userId);

  const { setValues, ...childUserFormikValues } = useUpdateAccountUser({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      roleName: ENUM_CHILD_USER_TYPE.ACCOUNT_MANAGER,
      id: "",
      owner: false,
      userNotification: [],
      status:
        accountUsers?.readAccountUsers?.childUsers[0]?.otherUserDetail?.status,
      parameterFilter:
        accountUsers?.readAccountUsers?.childUsers[0]?.otherUserDetail
          ?.parameterFilter,
    },
    appChildUserValidationSchema,
    onSubmit(res, err) {
      if (err) {
        Toast({ type: "error", message: err.message });
        return;
      }
      if (res?.code === "200") {
        Toast({ type: "success", message: res.message });
      }
      setUpdate(false);
    },
  });

  const { data, loading: readAccountTagLoading } = useReadAccountTag({
    tagType: ENUM_ACCOUNT_TAG_TYPE.CARD,
  });

  useEffect(() => {
    if (
      accountUsers?.readAccountUsers &&
      accountUsers?.readAccountUsers?.childUsers
    ) {
      const userNotificationsModified =
        accountUsers?.readAccountUsers?.childUsers[0]?.otherUserDetail?.userNotification.map(
          (element: NotificationsType, index: number) => {
            return {
              ...element,
              email:
                element?.communicationMode ===
                  ENUM_NOTIFICATION_CHANNEL.EMAIL ||
                element?.communicationMode ===
                  ENUM_NOTIFICATION_CHANNEL.EMAIL_AND_SMS,
              sms:
                element?.communicationMode === ENUM_NOTIFICATION_CHANNEL.SMS ||
                element?.communicationMode ===
                  ENUM_NOTIFICATION_CHANNEL.EMAIL_AND_SMS,
            };
          },
        );
      setValues({
        firstName: accountUsers?.readAccountUsers?.childUsers[0].firstName,
        lastName: accountUsers?.readAccountUsers?.childUsers[0].lastName,
        email: accountUsers?.readAccountUsers?.childUsers[0].email,
        phoneNumber: phoneFormatter(
          accountUsers?.readAccountUsers?.childUsers[0].phoneNumber || "",
        ),
        roleName:
          accountUsers?.readAccountUsers?.childUsers[0].roles[0].role.name,
        id: accountUsers?.readAccountUsers?.childUsers[0].id,
        owner: readCurrentUserAuth.primaryOwnerId === userId,
        userNotification: userNotificationsModified,
        parameterFilter:
          accountUsers?.readAccountUsers?.childUsers[0]?.otherUserDetail
            ?.parameterFilter,
        status:
          accountUsers?.readAccountUsers?.childUsers[0]?.otherUserDetail
            ?.status,
      });
      setNotifications(userNotificationsModified);
      setParameterFilter(
        accountUsers?.readAccountUsers?.childUsers[0]?.otherUserDetail
          ?.parameterFilter,
      );
    }
  }, [accountUsers]);
  return (
    <UserUI
      {...{
        accountUsersLoading,
        tabs,
        userId,
        update,
        setUpdate,
        notifications,
        setNotifications,
        parameterFilter,
        setParameterFilter,
        setValues,
        cardtags: data?.readAccountTags?.accountTagList?.CARD?.active,
        ...childUserFormikValues,
      }}
    />
  );
};
