/** @format */

import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { usePlatformDetect } from "@roadflex/react-hooks";
import {
  AccountSettingDisplayType,
  AccountTagInput,
  AccountTagType,
  CardShortened,
  TabsType,
} from "@roadflex/types";
import classNames from "classnames";
import { FormikProps } from "formik";
import Router, { useRouter } from "next/router";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Menu } from "primereact/menu";
import React, { createRef, useRef } from "react";
import { Toast } from "../../../src/toast-message/toast";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { dateTemplate } from "../../datatable/common-templates/common-templates";
import { newPaginationTemplate } from "../../datatable/pagination-templates/new-pagination-template";
import { GenericActionModal } from "../../modals/generic-action-modal/generic-action-modal";

type AccountTagsUIProps = FormikProps<AccountTagInput> & {
  currentTags: AccountTagType[];
  currentArchivedTags: AccountTagType[];
  openApplyCardTag: boolean;
  setOpenApplyCardTag: (value: boolean) => void;
  deliveredCards: CardShortened[];
  loading: boolean;
  tagDescriptionTemplate: (rowData: AccountTagType) => React.ReactNode;
  tagIdTemplate: (rowData: AccountTagType) => React.ReactNode;
  tagTitleTemplate: (rowData: AccountTagType) => React.ReactNode;
  tabs: TabsType[];
  tabOpen: number;
  setTabOpen: (value: number) => void;
  maxAccountTagLimit: number;
  readAccountDisplaySetting: AccountSettingDisplayType;
  handleSubmitApplyCardTags: (
    e?: React.FormEvent<HTMLFormElement> | undefined,
  ) => void;
  setFieldValueApplyCardTags: (field: string, value: string[] | string) => void;
  isSubmittingApplyCardTags: boolean;
  numberOfTags: number;
};

export const AccountTagsUI = ({
  currentTags,
  currentArchivedTags,
  openApplyCardTag,
  setOpenApplyCardTag,
  deliveredCards,
  tagIdTemplate,
  tagTitleTemplate,
  tagDescriptionTemplate,
  loading,
  handleSubmit,
  isSubmitting,
  values,
  setValues,
  tabs,
  tabOpen,
  setTabOpen,
  maxAccountTagLimit,
  readAccountDisplaySetting,
  handleSubmitApplyCardTags,
  setFieldValueApplyCardTags,
  isSubmittingApplyCardTags,
  numberOfTags,
  ...rest
}: AccountTagsUIProps) => {
  const { isMobile } = usePlatformDetect();
  const router = useRouter();
  const archivedAccountTagsMenuRefs = useRef<(React.RefObject<Menu> | null)[]>(
    [],
  );
  archivedAccountTagsMenuRefs.current = currentTags.map(
    (_, i) => archivedAccountTagsMenuRefs.current[i] ?? createRef(),
  );

  return (
    <div className="flex-col flex flex-1 m-4 mt-0 overflow-x-hidden md:px-4 md:pb-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="text-xl font-bold md:my-12 text-brown-500 md:text-2xl">
        {`Account Tags`.toUpperCase()}
      </div>
      <div className="mt-5 md:mt-0">
        <div className="p-8 overflow-hidden bg-white rounded-md shadow">
          <div className="flex flex-row justify-end w-full">
            <div className="flex items-center gap-4">
              <Button
                size={ButtonSize.AppSize}
                variant={ButtonVariant.AppOrange}
                onClick={() => {
                  if (numberOfTags >= maxAccountTagLimit) {
                    Toast({
                      type: "error",
                      message: `A maximum of ${maxAccountTagLimit} account tags allowed for your account`,
                    });
                  } else {
                    Router.push("/account-tags/new");
                  }
                }}
                className="flex flex-row items-center gap-2 p-2 text-sm step-2"
              >
                Add Tag
              </Button>
            </div>
          </div>
          <div className="border-b border-brown-500">
            <nav className="flex -mb-px space-x-8 overflow-auto">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tabOpen === tab.id
                      ? "border-black text-black"
                      : "border-transparent hover:border-black",
                    "whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm cursor-pointer",
                  )}
                  onClick={(e) => {
                    e.preventDefault();
                    setTabOpen(tab.id);
                  }}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
          <div className={`mt-6`}>
            <div className="flex flex-row items-center justify-between w-full mt-10">
              <div className="font-semibold text-brown-500 md:text-base">
                Active
              </div>
            </div>
            <div className="min-w-full overflow-hidden overflow-x-auto align-middle">
              <DataTable
                pageLinkSize={10}
                size="small"
                stripedRows
                value={currentTags}
                loading={loading}
                paginator
                dataKey="id"
                style={{
                  fontFamily: "Inter",
                }}
                paginatorTemplate={newPaginationTemplate}
                responsiveLayout={isMobile() ? "stack" : "scroll"}
                emptyMessage="No available records"
                className="!bg-white custom-table !text-brown-500 !border-brown-500"
                sortMode={"single"}
                rows={10}
                sortField="createdAt"
                sortOrder={-1}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                rowsPerPageOptions={[10, 20, 50]}
              >
                <Column
                  sortable
                  field="createdAt"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  header={`Date (${readAccountDisplaySetting?.timeZone})`}
                  body={(r) =>
                    dateTemplate(
                      r.createdAt,
                      readAccountDisplaySetting?.timeZone,
                    )
                  }
                />
                <Column
                  sortable
                  field="tagId"
                  header="Tag ID"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={tagIdTemplate}
                />
                <Column
                  sortable
                  field="title"
                  header="Tag Name"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={tagTitleTemplate}
                />
                <Column
                  sortable
                  field="description"
                  header="Tag Description"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={tagDescriptionTemplate}
                />
                <Column
                  headerStyle={{ width: "3em" }}
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base step-3"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={(e, props) => (
                    <div className="relative flex">
                      {tabOpen !== 2 && (
                        <Menu
                          model={[
                            {
                              label: "Edit",
                              icon: "pi pi-fw pi-pencil",
                              command: () => {
                                router.push(`/account-tags/${e.id}`);
                              },
                            },
                            {
                              label: "Archive",
                              icon: "pi pi-fw pi-trash",
                              command: () => {
                                setValues({ id: e.id });
                                handleSubmit();
                              },
                            },
                          ]}
                          popup
                          ref={
                            archivedAccountTagsMenuRefs.current[props.rowIndex]
                          }
                        />
                      )}
                      {tabOpen === 2 && (
                        <Menu
                          model={[
                            {
                              label: "Edit",
                              icon: "pi pi-fw pi-pencil",
                              command: () => {
                                router.push(`/account-tags/${e.id}`);
                              },
                            },
                            {
                              label: "Archive",
                              icon: "pi pi-fw pi-trash",
                              command: () => {
                                setValues({ id: e.id });
                                handleSubmit();
                              },
                            },
                            {
                              label: "Apply",
                              icon: "pi pi-fw pi-check",
                              command: () => {
                                setOpenApplyCardTag(true);
                                setFieldValueApplyCardTags("cardTagId", e.id);
                              },
                            },
                          ]}
                          popup
                          ref={
                            archivedAccountTagsMenuRefs.current[props.rowIndex]
                          }
                        />
                      )}
                      <Button
                        variant={ButtonVariant.Transparent}
                        className="!p-0"
                        onClick={(event) =>
                          archivedAccountTagsMenuRefs.current[
                            props.rowIndex
                          ]?.current?.toggle(event)
                        }
                      >
                        <EllipsisVerticalIcon className="w-5"></EllipsisVerticalIcon>
                      </Button>
                    </div>
                  )}
                ></Column>
              </DataTable>
            </div>
            <div className="flex flex-row items-center justify-between w-full mt-10">
              <div className="font-semibold text-brown-500 md:text-base">
                Archived
              </div>
            </div>
            <div className="min-w-full mt-2 overflow-hidden overflow-x-auto align-middle">
              <DataTable
                pageLinkSize={10}
                size="small"
                stripedRows
                value={currentArchivedTags}
                loading={loading}
                paginator
                dataKey="id"
                style={{
                  fontFamily: "Inter",
                }}
                paginatorTemplate={newPaginationTemplate}
                responsiveLayout={isMobile() ? "stack" : "scroll"}
                emptyMessage="No available records"
                className="!bg-white custom-table !text-brown-500 !border-brown-500"
                sortMode={"single"}
                rows={10}
                sortField="createdAt"
                sortOrder={-1}
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                rowsPerPageOptions={[10, 20, 50]}
              >
                <Column
                  sortable
                  field="createdAt"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  header={`Date (${readAccountDisplaySetting?.timeZone})`}
                  body={(r) =>
                    dateTemplate(
                      r.createdAt,
                      readAccountDisplaySetting?.timeZone,
                    )
                  }
                />
                <Column
                  sortable
                  field="tagId"
                  header="Tag ID"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={tagIdTemplate}
                />
                <Column
                  sortable
                  field="title"
                  header="Tag Name"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={tagTitleTemplate}
                />
                <Column
                  sortable
                  field="description"
                  header="Tag Description"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={tagDescriptionTemplate}
                />
              </DataTable>
            </div>
          </div>
        </div>
        {openApplyCardTag && (
          <GenericActionModal
            title={`Apply Card Tag`}
            helpGuide={`Card tags can be applied to multiple cards. Once
                            applied, existing settings will be overwritten.`}
            open={openApplyCardTag}
            setOpen={setOpenApplyCardTag}
            buttonName="Apply Card Tag"
            action={handleSubmitApplyCardTags}
            updating={isSubmittingApplyCardTags}
            deliveredCards={deliveredCards}
            setFieldValue={setFieldValueApplyCardTags}
          ></GenericActionModal>
        )}
      </div>
    </div>
  );
};
