/** @format */

import { ENUM_COLOR_SCHEME } from "@prisma/client";
import { AccountSettingDisplayType, UserAuthType } from "@roadflex/types";
import classNames from "classnames";
import getConfig from "next/config";
import NavigationLinks from "./navigation-links";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;

export default function SideBar({
  show,
  toggle,
  readCurrentUserAuth,
  readAccountDisplaySetting,
}: {
  show?: boolean;
  toggle?: () => void;
  readCurrentUserAuth?: UserAuthType;
  readAccountDisplaySetting?: AccountSettingDisplayType;
}) {
  const customer = readCurrentUserAuth?.fuelCard?.customerType?.split("_")[1];
  let customerTypeLabel = "BASIC";
  if (customer === "PLUS") {
    customerTypeLabel = "PREMIUM";
  } else if (customer === "ENTERPRISE") {
    customerTypeLabel = "ENTERPRISE";
  }

  return (
    <div
      className={classNames(
        readAccountDisplaySetting?.colorScheme === ENUM_COLOR_SCHEME.DEFAULT
          ? "bg-gradient-to-br from-brown-850 to-brown-750"
          : readAccountDisplaySetting?.colorScheme ===
            ENUM_COLOR_SCHEME.COOL_AND_FRESH
          ? "bg-coolFresh2"
          : readAccountDisplaySetting?.colorScheme ===
            ENUM_COLOR_SCHEME.NATURAL_AND_EARTHY
          ? "bg-earthy2"
          : readAccountDisplaySetting?.colorScheme ===
            ENUM_COLOR_SCHEME.FUTURISTIC
          ? "bg-futuristic2"
          : "bg-gradient-to-br from-brown-850 to-brown-750",
        "h-full hidden md:flex md:w-72 md:flex-col md:fixed md:inset-y-0",
      )}
    >
      <div className="flex flex-col flex-grow pt-5 overflow-y-auto hide-scrollbar">
        <div className="relative h-16 mx-4 mt-2">
          <img
            className=""
            src={`${NEXT_PUBLIC_CDN_URL}/svg/roadflex-full-logo.svg`}
            alt="RoadFlex"
          />
        </div>
        {readAccountDisplaySetting?.showCompanyName && (
          <div className="mb-1 text-lg font-semibold text-center text-white">
            {(readCurrentUserAuth?.businessName || "").toUpperCase()}
          </div>
        )}
        {readCurrentUserAuth?.isParentCompanyUser === false && (
          <div className="mb-4 text-base font-semibold text-center text-gray-200">
            {customerTypeLabel}
          </div>
        )}
        <NavigationLinks
          readCurrentUserAuth={readCurrentUserAuth}
        ></NavigationLinks>
      </div>
    </div>
  );
}
