import { BuildingLibraryIcon } from "@heroicons/react/24/outline";
import { PlaidType } from "@roadflex/types";
import { loadStripe } from "@stripe/stripe-js";
import getConfig from "next/config";
import { DropdownOptionGroupTemplateType } from "primereact/dropdown";
import React, { useCallback } from "react";
import {
  PlaidLinkOnSuccess,
  PlaidLinkOptions,
  usePlaidLink,
} from "react-plaid-link";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { Select } from "../../dropdowns";

const { publicRuntimeConfig } = getConfig();

const { NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY } = publicRuntimeConfig;

const stripeResponse = loadStripe(`${NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY}`);

interface PlaidPaymentMethodsAltUIProps {
  savePlaidLoading: boolean;
  plaidData: PlaidType[];
  businessId?: string;
  plaidToken?: { linkToken: string };
  handleSubmit: (data: {
    isUpdateMode?: boolean;
    plaidId?: string;
    publicToken: string;
    institution: string;
    businessId?: string;
  }) => void;
  plaidTokenLoading: boolean;
  selectedPlaidAccount: string;
  setSelectedPlaidAccount: (value: string) => void;
}
export const PlaidPaymentMethodsAltUI = (
  props: PlaidPaymentMethodsAltUIProps,
) => {
  const {
    savePlaidLoading,
    plaidData,
    plaidToken,
    handleSubmit,
    plaidTokenLoading,
    selectedPlaidAccount,
    setSelectedPlaidAccount,
  } = props;
  const onSuccess = useCallback<PlaidLinkOnSuccess>((token, metadata) => {
    const data = {
      institution: metadata?.institution?.name || "",
      publicToken: token || "",
    };
    handleSubmit(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const config: PlaidLinkOptions = {
    token: plaidToken?.linkToken || "",
    onSuccess,
    // onExit
    // onEvent
  };

  const { open, ready } = usePlaidLink(config);

  const groupedPlaidAccounts = plaidData.map((account) => {
    return {
      label: account.institution,
      items: account.authInfo?.accounts.map((subaccount) => {
        return {
          label: `Account ending with ${subaccount.mask} (${subaccount.subtype})`,
          value: subaccount.account_id,
        };
      }),
    };
  });

  const groupedItemTemplate = (option: DropdownOptionGroupTemplateType) => {
    const data = option as unknown as { label: string };
    return (
      <div className="p-d-flex p-ai-center">
        <div>
          <span className="font-bold">{data.label}</span>
        </div>
      </div>
    );
  };

  return (
    <div className="w-full">
      <div className="flex flex-col justify-between w-full">
        {savePlaidLoading && (
          <div className="w-full mb-2 text-center">Updating...</div>
        )}
        <div className="mb-1 text-sm">Select Bank Account</div>
        <Select
          value={selectedPlaidAccount}
          options={groupedPlaidAccounts}
          onChange={(e) => {
            setSelectedPlaidAccount(e.value);
          }}
          optionLabel="label"
          optionGroupLabel="label"
          optionGroupChildren="items"
          optionGroupTemplate={groupedItemTemplate}
        ></Select>
        {plaidData.length === 0 && (
          <div className="h-[125px] flex justify-center items-center flex-col">
            <div>
              <BuildingLibraryIcon className="w-20 h-12"></BuildingLibraryIcon>
            </div>
            <div className="max-w-[200px] text-center md:text-xl">
              No bank account connected
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col w-full">
        <div className="flex flex-col mt-2">
          <Button
            variant={ButtonVariant.AppOrange}
            size={ButtonSize.AppSize}
            className="flex flex-row items-center w-[fit-content]"
            onClick={() => open()}
            loading={plaidTokenLoading}
            disabled={!ready || plaidData.length === 5 || plaidTokenLoading}
          >
            Connect New Bank
          </Button>
          <div className="mt-2 text-sm italic">(Max 5 connections)</div>
        </div>
      </div>
    </div>
  );
};
