import {
  ArrowRightCircleIcon,
  BanknotesIcon,
  CheckIcon,
  ReceiptPercentIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import {
  ENUM_TRANSACTION_FLAG,
  ENUM_TRANSACTION_STATUS,
  ENUM_TRANSACTION_TYPE,
} from "@prisma/client";
import { dollarFormatter } from "@roadflex/constants";
import {
  AuthorizationType,
  CardsFilterOptions,
  TransactionType,
} from "@roadflex/types";
import { convertISOStringToDate, humanizeEnum } from "@roadflex/utils";
import classNames from "classnames";
import router from "next/router";
import {
  ColumnBodyOptions,
  ColumnFilterElementTemplateOptions,
} from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { Toast } from "../../toast-message/toast";

export const dateTemplate = (rowData: TransactionType | AuthorizationType) => {
  return (
    <span className="whitespace-nowrap">
      <div>
        {rowData.authorizationDate ? (
          <>
            {" "}
            <div>
              {
                convertISOStringToDate(
                  new Date(rowData.authorizationDate).toString(),
                  false,
                  false,
                  true,
                ).split(",")[0]
              }
            </div>
            <div className="text-sm">
              {
                convertISOStringToDate(
                  rowData.authorizationDate.toString(),
                  false,
                  false,
                  true,
                ).split(",")[1]
              }
            </div>
          </>
        ) : (
          "-"
        )}
      </div>
    </span>
  );
};

export const fuelDiscountTemplate = (rowData: TransactionType) => {
  return (
    <span className="whitespace-nowrap">
      {rowData?.fuelDiscountAmount > 0
        ? dollarFormatter(rowData?.fuelDiscountAmount / 100)
        : "-"}
    </span>
  );
};

export const transactionReceiptStatusTemplate = (rowData: TransactionType) => {
  return (
    <span className="whitespace-nowrap">
      {rowData?.transactionReceiptRequired === true && (
        <div>
          {Number(rowData.receiptUploaded) > 0 && (
            <div>
              <ReceiptPercentIcon className="w-5 h-5" aria-hidden="true" />
            </div>
          )}
          {Number(rowData.receiptUploaded) === 0 && (
            <div>
              <XCircleIcon className="w-5 h-5" aria-hidden="true" />
            </div>
          )}
        </div>
      )}
      {!rowData?.transactionReceiptRequired && <div>Not required</div>}
    </span>
  );
};

export const stateTemplate = (rowData: TransactionType | AuthorizationType) => {
  return (
    <span className="whitespace-nowrap">
      {rowData?.stripeResponse?.merchant_data?.city
        ? `${rowData?.stripeResponse?.merchant_data?.city}, `
        : ""}
      {rowData.merchantState ? rowData.merchantState : "-"}{" "}
      {rowData.merchantPostCd ? rowData.merchantPostCd : "-"}
    </span>
  );
};

export const merchantCategory = (
  rowData: TransactionType | AuthorizationType,
) => {
  return (
    <span className="whitespace-nowrap">
      {rowData?.stripeResponse?.merchant_data?.category
        ? `${rowData?.stripeResponse?.merchant_data?.category
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")}`
        : ""}
    </span>
  );
};

export const authAmountTemplate = (rowData: AuthorizationType) => {
  return (
    <span className="whitespace-nowrap">
      {rowData.authorizationAmount
        ? dollarFormatter(rowData.authorizationAmount / 100)
        : "-"}
    </span>
  );
};

export const flaggedTransactionReason = (rowData: TransactionType) => {
  return (
    <span className="">
      {rowData.flagReason?.length === 1 &&
        rowData.flagReason?.includes(ENUM_TRANSACTION_FLAG.FUEL_TYPE) && (
          <span>Wrong Fuel Type</span>
        )}
      {rowData.flagReason?.length === 1 &&
        rowData.flagReason?.includes(ENUM_TRANSACTION_FLAG.TANK_CAPACITY) && (
          <span>Fuel purchase exceeds tank capacity</span>
        )}
      {rowData.flagReason?.length === 1 &&
        rowData.flagReason?.includes(
          ENUM_TRANSACTION_FLAG.SUSPICIOUS_FUELING,
        ) && <span>Fuel Level Anomaly</span>}
      {rowData.flagReason?.length === 2 && (
        <span>Wrong Fuel Type and Fuel Purchase Exceeds Tank Capacity</span>
      )}
    </span>
  );
};

export const transactionAmountTemplate = (rowData: TransactionType) => {
  return (
    <span className="whitespace-nowrap">
      {rowData.transactionAmount
        ? dollarFormatter(rowData.transactionAmount / 100)
        : "-"}
    </span>
  );
};

export const declineReasonTemplate = (rowData: AuthorizationType) => {
  return (
    <span className="whitespace-nowrap">
      {rowData.declineReason ? humanizeEnum(rowData.declineReason) : "-"}
    </span>
  );
};

export const cardLastFourTemplate = (
  rowData: TransactionType | AuthorizationType,
) => {
  return <span className="whitespace-nowrap">{rowData?.card?.lastFour}</span>;
};

export const driverTemplate = (
  rowData: TransactionType | AuthorizationType,
) => {
  return (
    <span className="whitespace-nowrap">{rowData?.driverName || "-"}</span>
  );
};

export const driverTemplateTransaction = (
  rowData: TransactionType,
  showTag?: boolean,
) => {
  return (
    <div>
      <div className="whitespace-nowrap">{rowData?.driverName || "-"}</div>
      {showTag && rowData?.driverTagId && (
        <div className="text-xs italic text-brown-400">
          (Tag: {rowData?.driverTagName})
        </div>
      )}
    </div>
  );
};

export const vehicleTemplate = (
  rowData: TransactionType | AuthorizationType,
) => {
  return (
    <span className="whitespace-nowrap">{rowData?.vehicleName || "-"}</span>
  );
};

export const vehicleTemplateTransaction = (
  rowData: TransactionType,
  showTag?: boolean,
) => {
  return (
    <div>
      <div className="whitespace-nowrap">{rowData?.vehicleName || "-"}</div>
      {showTag && rowData?.vehicleTagId && (
        <div className="text-xs italic text-brown-400">
          (Tag: {rowData?.vehicleTagName})
        </div>
      )}
    </div>
  );
};

export const vehicleVINTemplate = (
  rowData: TransactionType | AuthorizationType,
) => {
  return (
    <span className="whitespace-nowrap">{rowData?.vehicleVIN || "-"}</span>
  );
};

export const authStatusTemplate = (
  rowData: AuthorizationType,
  options?: ColumnBodyOptions,
  isMobile = false,
) => {
  return rowData?.approvalStatus ? (
    <span
      className={classNames(
        isMobile ? "!p-1" : "",
        "inline-flex items-center px-2.5 py-0.5 rounded-full bg-green-100 text-green-800 capitalize",
      )}
    >
      {isMobile ? <CheckIcon className="w-4 h-4"></CheckIcon> : "Approved"}
    </span>
  ) : (
    <span
      className={classNames(
        isMobile ? "!p-1" : "",
        "inline-flex items-center px-2.5 py-0.5 rounded-full bg-red-100 text-red-800 capitalize",
      )}
    >
      Rejected
    </span>
  );
};

export const transactionStatusTemplate = (
  rowData: TransactionType,
  options?: ColumnBodyOptions,
  isMobile = false,
) => {
  return rowData.transactionStatus &&
    rowData.transactionStatus === "CAPTURE" ? (
    <span
      className={classNames(
        isMobile ? "!p-1" : "",
        "inline-flex items-center px-2.5 py-0.5 rounded-full bg-green-100 text-green-800 capitalize",
      )}
    >
      {isMobile ? <CheckIcon className="w-4 h-4"></CheckIcon> : "Approved"}
    </span>
  ) : (
    <span
      className={classNames(
        isMobile ? "!p-1" : "",
        "inline-flex items-center px-2.5 py-0.5 rounded-full bg-brown-100 text-brown-800 capitalize",
      )}
    >
      {isMobile ? (
        <BanknotesIcon className="w-4 h-4"></BanknotesIcon>
      ) : (
        "Refunded"
      )}
    </span>
  );
};

const goToTransactionDetailsPage = (transactionId: string) => {
  router.push(`/transactions/${transactionId}`);
};

const goToAuthorizationDetailsPage = (authorizationId: string) => {
  router.push(`/transactions/pending/${authorizationId}`);
};

export const transactionsActionTemplate = (rowData: TransactionType) => {
  return (
    <Button
      variant={ButtonVariant.Transparent}
      size={ButtonSize.Small}
      className="text-xs"
      onClick={() => {
        if (rowData?.transactionType === ENUM_TRANSACTION_TYPE.MONTHLY_FEE) {
          Toast({
            type: "info",
            message: `${rowData?.purchaseDetails?.reason} - Card(s): ${
              rowData?.purchaseDetails?.cards
            }, Fee per card: ${dollarFormatter(
              Number(rowData?.purchaseDetails?.cost) / 100,
            )}`,
          });
        } else if (
          rowData?.transactionStatus === ENUM_TRANSACTION_STATUS.REFUND
        ) {
          Toast({ type: "info", message: "Transaction refunded" });
        } else {
          goToTransactionDetailsPage(rowData?.id);
        }
      }}
    >
      <ArrowRightCircleIcon className="w-5 h-5" aria-hidden="true" />
    </Button>
  );
};

export const transactionsActionMobileTemplate = (rowData: TransactionType) => {
  return (
    <Button
      variant={ButtonVariant.AppOrange}
      size={ButtonSize.AppSize}
      onClick={() => {
        if (rowData?.transactionType === ENUM_TRANSACTION_TYPE.MONTHLY_FEE) {
          Toast({
            type: "info",
            message: `${rowData?.purchaseDetails?.reason} - Card(s): ${
              rowData?.purchaseDetails?.cards
            }, Fee per card: ${dollarFormatter(
              Number(rowData?.purchaseDetails?.cost) / 100,
            )}`,
          });
        } else if (
          rowData?.transactionStatus === ENUM_TRANSACTION_STATUS.REFUND
        ) {
          Toast({ type: "info", message: "Transaction refunded" });
        } else {
          goToTransactionDetailsPage(rowData?.id);
        }
      }}
    >
      More Details
    </Button>
  );
};

export const authorizationActionTemplate = (rowData: AuthorizationType) => {
  return (
    <Button
      variant={ButtonVariant.Transparent}
      size={ButtonSize.Small}
      className="text-xs"
      onClick={() => {
        goToAuthorizationDetailsPage(rowData?.id);
      }}
    >
      <ArrowRightCircleIcon className="w-5 h-5" aria-hidden="true" />
    </Button>
  );
};

export const cardItemTemplate = (option: CardsFilterOptions) => {
  return (
    <div className="whitespace-nowrap">
      <span className="image-text">{option.lastFour}</span>
    </div>
  );
};

export const cardFilterTemplate = (
  options: ColumnFilterElementTemplateOptions,
  cardsFilterOptions: CardsFilterOptions[],
) => {
  return (
    <MultiSelect
      value={options.value}
      options={cardsFilterOptions}
      onChange={(e) => {
        options.filterCallback(e.value);
      }}
      style={{
        fontFamily: "Inter",
        fontSize: "0.75rem",
      }}
      placeholder="Any"
      className="custom-multiselect-filter"
      panelClassName="custom-multiselect-filter-panel"
      optionLabel="lastFour"
      optionValue="lastFour"
      maxSelectedLabels={1}
      itemTemplate={cardItemTemplate}
    />
  );
};
