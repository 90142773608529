/** @format */

import { ENUM_MAIL_TYPE } from "@prisma/client";
import { useLandingPageFormSubmission } from "@roadflex/react-hooks-www";
import { handleGoogleReCaptchaSubmit, phoneFormatter } from "@roadflex/web-lib";
import getConfig from "next/config";
import { useRouter } from "next/router";
import { Dialog } from "primereact/dialog";
import { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../../buttons";
import { TextInput } from "../../inputs";
import { Toast } from "../../toast-message/toast";

const { publicRuntimeConfig } = getConfig();
const { RECAPTCHA_SITE_KEY } = publicRuntimeConfig;

type Props = {
  fullReport: boolean;
  setFullReport: (value: boolean) => void;
  numberTrucks: number;
  gallonsPerFillUp: number;
  fillUpsPerWeek: number;
  currentFuelCard: string;
  currentTelematics: string;
};

export function SavingsModal({
  fullReport,
  setFullReport,
  numberTrucks,
  gallonsPerFillUp,
  fillUpsPerWeek,
  currentFuelCard,
  currentTelematics,
}: Props) {
  const router = useRouter();
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    errors,
    isSubmitting,
    setFieldValue,
    touched,
  } = useLandingPageFormSubmission({
    initialValues: {
      landingPageMailType: ENUM_MAIL_TYPE.SAVINGS,
      firstName: "",
      lastName: "",
      companyName: "",
      email: "",
      phoneNumber: "",
      numberTrucks,
      gallonsPerFillUp,
      fillUpsPerWeek,
      currentFuelCard,
      currentTelematics,
    },
    onSubmit(res, err) {
      if (err) {
        Toast({ type: "error", message: err.message });
        return;
      }
      if (res?.code === "200") {
        Toast({ type: "success", message: res.message });
        setFullReport(false);
        router.push({
          pathname: "/",
        });
      } else {
        setFullReport(false);
        Toast({
          type: "error",
          message: "An error occurred when you submitted the request",
        });
      }
    },
  });
  const captchaRef = useRef<ReCAPTCHA>(null);
  return (
    <Dialog
      header={<div className="text-sm">Get Full Report</div>}
      style={{ minWidth: "60vw", maxWidth: "80vw", fontFamily: "Inter" }}
      visible={fullReport}
      onHide={() => setFullReport(false)}
      closeOnEscape={true}
      closable={false}
      footer={<></>}
    >
      <form
        className="w-full border-0"
        onSubmit={(event) => {
          handleGoogleReCaptchaSubmit(event, captchaRef, handleSubmit);
        }}
        // onSubmit={handleSubmit}
      >
        <div className="grid w-full gap-2 text-black md:gap-6">
          <div className="relative col-span-12 md:col-span-12">
            <TextInput
              name="firstName"
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.firstName && Boolean(errors.firstName)}
              errorMessage={errors.firstName}
              touched={touched.firstName}
              variant="medium"
              placeholder="First (and Middle) Name"
              required
            ></TextInput>
          </div>
          <div className="relative col-span-12 md:col-span-12">
            <TextInput
              name="lastName"
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.lastName && Boolean(errors.lastName)}
              errorMessage={errors.lastName}
              touched={touched.lastName}
              variant="medium"
              placeholder="Last Name"
              required
            ></TextInput>
          </div>
          <div className="relative col-span-12 md:col-span-12">
            <TextInput
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.email && Boolean(errors.email)}
              errorMessage={errors.email}
              touched={touched.email}
              variant="medium"
              placeholder="Email"
              required
            ></TextInput>
          </div>
          <div className="relative col-span-12 md:col-span-12">
            <TextInput
              name="phoneNumber"
              type="tel"
              value={values.phoneNumber}
              onChange={(event) =>
                setFieldValue(
                  "phoneNumber",
                  phoneFormatter(event?.target?.value),
                )
              }
              onBlur={handleBlur}
              error={touched.phoneNumber && Boolean(errors.phoneNumber)}
              errorMessage={errors.phoneNumber}
              touched={touched.phoneNumber}
              variant="medium"
              placeholder="Phone Number"
              required
            ></TextInput>
          </div>
          <div className="relative col-span-12 md:col-span-12">
            <TextInput
              name="companyName"
              value={values.companyName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.companyName && Boolean(errors.companyName)}
              errorMessage={errors.companyName}
              touched={touched.companyName}
              variant="medium"
              placeholder="Company Name"
              required
            ></TextInput>
          </div>
        </div>
        <ReCAPTCHA
          className="invisible recaptcha"
          sitekey={RECAPTCHA_SITE_KEY}
          ref={captchaRef}
          size="invisible"
        />
        <div className="flex flex-row col-span-12 mt-8">
          <Button
            type={ButtonType.Button}
            variant={ButtonVariant.SecondaryOutline}
            size={ButtonSize.AppSize}
            disabled={isSubmitting}
            className="w-full"
            onClick={() => setFullReport(false)}
          >
            Cancel
          </Button>
          <Button
            type={ButtonType.Submit}
            variant={ButtonVariant.Orange}
            size={ButtonSize.AppSize}
            disabled={isSubmitting}
            loading={isSubmitting}
            className="w-full ml-3"
          >
            Confirm
          </Button>
        </div>
      </form>
    </Dialog>
  );
}

export default SavingsModal;
