import { ModalLoadingLargeSvg } from "@roadflex/react-svg";
import React, { useEffect, useState } from "react";

type AdminModalLoaderProps = {
  loadingText?: React.ReactNode;
  showLoading: boolean;
};

export const AdminModalLoader: React.FC<AdminModalLoaderProps> = ({
  loadingText = (
    <div className="flex flex-col items-center gap-6 text-center text-white drop-shadow-lg">
      <ModalLoadingLargeSvg />
      <div className="text-3xl font-bold text-orange-800">
        Loading, please do not refresh!
      </div>
    </div>
  ),
  showLoading,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isFadingIn, setIsFadingIn] = useState(false);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (showLoading) {
      setIsVisible(true);
      setTimeout(() => setIsFadingIn(true), 10);
    } else {
      setIsFadingIn(false);
      const timeout = setTimeout(() => setIsVisible(false), 300);
      return () => clearTimeout(timeout);
    }
  }, [showLoading]);

  return (
    <div className="z-[20000]">
      {(isVisible || showLoading) && (
        <div
          className={`fixed inset-0 z-50 flex items-center justify-center bg-white bg-opacity-70 transition-opacity duration-500 ease-out ${
            isFadingIn ? "opacity-100" : "opacity-0"
          }`}
        >
          <div className="p-8">{loadingText}</div>
        </div>
      )}
    </div>
  );
};

export default AdminModalLoader;
